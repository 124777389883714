import { FC, ReactNode, ReactNodeArray, createContext, useContext, useMemo } from 'react';
import { useRouter } from 'next/router';

import { SeoData } from '@api/seo/types';
import getConfig from "next/config";

const SeoContext = createContext<SeoData | null>(null);
SeoContext.displayName = 'SeoContext';

export const SeoProvider: FC<{ children: ReactNode | ReactNodeArray; seoContextData: SeoData }> = ({
    children,
    seoContextData,
}) => {
    const router = useRouter();

    const { publicRuntimeConfig } = getConfig();

    const currentRoute = useMemo(() => {
        let path = router.asPath.indexOf('?') === -1
            ? router.asPath
            : router.asPath.split('?')[0];

        return `${publicRuntimeConfig ? publicRuntimeConfig.host : ''}${path}`;
    }, [router.asPath]);

    return <SeoContext.Provider value={seoContextData}>
        <link rel="canonical" href={seoContextData.canonical || currentRoute}/>
        {children}
    </SeoContext.Provider>;
};

export const useSeoContext = () => {
    const context = useContext(SeoContext);

    if (!context) {
        throw new Error(`Hook useSeo must be used within SeoProvider`);
    }

    return context;
};
